import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useLocale } from "lib/hooks/useLocale";
import React, { useEffect, useMemo, useState } from "react";
import { DialogBody, DialogFooter, DialogHeader, GenericDialog } from "../dialogs/GenericDialog";
import { X } from "react-feather";
import PrimaryButton from "../buttons/PrimaryButton";
import DiscountedProductCard from "components/coupons/discounted-product-selector/DiscountedProductCard";
import {
  DiscountedProductSelectorItemType,
  DiscountedProductSelectorPayloadType,
  DiscountedProductSelectorProductDistributionType,
  DiscountedProductSelectorProductType,
  DiscountedProductSelectorProductVariantType,
  ShippingHandlesType,
} from "lib/types/checkout";
import DiscountedProductGroup from "components/coupons/discounted-product-selector/DiscountedProductGroup";
import { postRequest } from "lib/core/apiClient";
import OverlaySpinner from "../loaders/OverlaySpinner";
import { errorToast } from "lib/utils/toasters";
import { mutate } from "swr";
import FreeGiftsIcon from "assests/images/free-gifts-logo.png";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { analyticsEvents, analyticsTypes } from "lib/utils/constants";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";

interface DiscountedProductSelectorPopupProps {
  position?: "cart" | "checkout";
}

const DiscountedProductSelectorPopup: React.FC<DiscountedProductSelectorPopupProps> = ({
  position = "checkout",
}) => {
  const { t } = useLocale();
  const { sendAnalyticsEvent } = useSendAnalyticsEvent();
  const {
    state: {
      checkoutId,
      discountProductSelectorModal,
      discountedProductSelectorData,
      checkoutView,
      initialCheckoutStep,
      discountProductSelectorPayload,
      checkoutModal,
    },
    actions: {
      setCheckoutModal,
      setDiscountProductSelectorModal,
      setCheckoutView,
      setShippingHandles,
      updateCheckoutBasedOnCheckoutResponse,
      setDiscountProductSelectorPayload,
    },
  } = useCheckoutContext();
  const {
    state: { isAuthenticated },
  } = useAuthContext();
  const [openState, setOpenState] = useState<boolean[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hasMultipleGroups = useMemo(
    () => discountedProductSelectorData.items.length > 1,
    [discountedProductSelectorData],
  );

  useEffect(() => {
    if (openState.length === 0) {
      setOpenState(
        discountedProductSelectorData.items.map(
          (value: DiscountedProductSelectorItemType, index: number) => index === 0,
        ),
      );
    }
  }, [discountedProductSelectorData]);

  useEffect(() => {
    if (discountProductSelectorModal !== "NONE") {
      setCheckoutModal("NONE");
    }
  }, [discountProductSelectorModal, checkoutModal]);

  useEffect(() => {
    if (discountProductSelectorModal === "DISCOUNTED_PRODUCT_SELECTOR") {
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_ITEMS_SELECTOR_LOADED,
        eventFor: [analyticsTypes.SF_ANALYTICS],
        eventType: "load",
        metaData: {
          itemSelectorData: {
            discountCodes: discountCodes,
          },
        },
      });
    }
  }, [discountProductSelectorModal]);

  const updatedPayload: DiscountedProductSelectorPayloadType = useMemo(() => {
    const selectedVariants: Record<string, DiscountedProductSelectorProductVariantType[]> = {};
    discountedProductSelectorData.items.forEach((item: DiscountedProductSelectorItemType) => {
      if (!Boolean(selectedVariants[item.couponCode])) {
        selectedVariants[item.couponCode] = [];
      }
      item.products.forEach((product: DiscountedProductSelectorProductType) => {
        product.variants?.forEach((variant: DiscountedProductSelectorProductVariantType) => {
          if (Boolean(variant.selectedQuantity)) {
            if (selectedVariants[item.couponCode]) {
              selectedVariants[item.couponCode].push(variant);
            } else {
              selectedVariants[item.couponCode] = [variant];
            }
          }
        });
      });
    });

    const generatedPayload: DiscountedProductSelectorPayloadType = {
      selection: Object.entries(selectedVariants).map(([key, items]) => {
        return {
          discount_code: key,
          items: items?.map((variant: DiscountedProductSelectorProductVariantType) => ({
            variant_id: variant.id,
            quantity: variant.selectedQuantity,
          })),
        };
      }),
    };
    return generatedPayload;
  }, [discountedProductSelectorData]);

  const discountCodes = useMemo(() => {
    const discountCodes: string[] = [];
    discountedProductSelectorData?.items?.forEach((item: DiscountedProductSelectorItemType) => {
      if (!discountCodes?.includes(item?.couponCode)) {
        discountCodes.push(item?.couponCode);
      }
    });
    return discountCodes;
  }, [discountedProductSelectorData]);

  const appliedPayload: DiscountedProductSelectorPayloadType = useMemo(() => {
    const modifiedSelection = discountCodes.map((code: string) => {
      const couponIndex = discountProductSelectorPayload.selection.findIndex(
        (item) => item.discount_code === code,
      );
      if (couponIndex !== -1) {
        return {
          discount_code: code,
          items: discountProductSelectorPayload.selection[couponIndex].items,
        };
      }
      return {
        discount_code: code,
        items: [],
      };
    });
    return { selection: modifiedSelection };
  }, [discountProductSelectorPayload, discountCodes, discountedProductSelectorData]);

  const onSubmit = async (payload: DiscountedProductSelectorPayloadType) => {
    setIsLoading(true);
    try {
      let response: any;
      if (isAuthenticated) {
        response = await postRequest(`/checkout/v1/checkout/${checkoutId}/discount-items`, payload);
      } else {
        response = await postRequest(`/v1/checkout/${checkoutId}/discount-items`, payload, "CHECKOUT_PUBLIC");
      }
      if (!response) {
        errorToast(t("unable_to_add"));
        return;
      }
      sendAnalyticsEvent({
        eventName: analyticsEvents.FLO_ITEMS_SELECTOR_SUBMITTED,
        eventFor: [analyticsTypes.SF_ANALYTICS],
        eventType: "click",
        metaData: {
          itemSelectorData: {
            selection: payload.selection,
          },
        },
      });
      setDiscountProductSelectorPayload(payload);
      updateCheckoutBasedOnCheckoutResponse(response);
      Boolean(isAuthenticated)
        ? mutate(`/checkout/v1/checkout/${checkoutId}/upsell`)
        : mutate(`/v1/checkout/${checkoutId}/upsell`);

      Boolean(isAuthenticated)
        ? mutate(`/checkout/v1/checkout/${checkoutId}/discounts`)
        : mutate(`/v1/checkout/${checkoutId}/discounts`);

      const isAddressServiceable = response?.pricing?.serviceable ?? false;
      if (!Boolean(isAddressServiceable) && position !== "cart") {
        if (Boolean(checkoutView === "PAYMENTS") && Boolean(initialCheckoutStep !== "PAYMENTS")) {
          setCheckoutView("ADDRESS_LIST");
        }
        if (Boolean(checkoutView === "PAYMENTS") && Boolean(initialCheckoutStep === "PAYMENTS")) {
          setCheckoutModal("ADDRESS_LIST");
        }
        errorToast(t("serviceability_error"), 5000);
        return;
      }

      const shippingHandles = response?.metadata?.available_shipping_handles;
      const showShippingHandles = response?.metadata?.show_shipping_handle_selector ?? false;
      setShippingHandles(shippingHandles as ShippingHandlesType);
      if (Boolean(showShippingHandles) && checkoutView === "PAYMENTS" && initialCheckoutStep !== "PAYMENTS") {
        setCheckoutModal("SHIPPING_HANDLES");
      } else {
        mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
        mutate(`UPI_INTENT`);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setDiscountProductSelectorModal("NONE");
    }
  };

  const discountedProductSelectorProductDistribution: DiscountedProductSelectorProductDistributionType =
    useMemo(() => {
      let freebies = 0;
      let discountedProducts = 0;
      discountedProductSelectorData.items.forEach((item: DiscountedProductSelectorItemType) => {
        if (item.concessionAmount === 100) {
          freebies += item.quantity;
        } else {
          discountedProducts += item.quantity;
        }
      });
      return {
        freebies: freebies,
        discountedProducts: discountedProducts,
        onlyFreebies: freebies === discountedProductSelectorData.totalItems,
        onlyDiscountedProducts: discountedProducts === discountedProductSelectorData.totalItems,
        freebiesAndDiscountedProducts: freebies > 0 && discountedProducts > 0,
      };
    }, [discountedProductSelectorData]);

  return (
    <>
      <GenericDialog
        isOpen={discountProductSelectorModal === "DISCOUNTED_PRODUCT_SELECTOR"}
        setIsOpen={() => onSubmit(appliedPayload)}
        translateAxis="y"
        customClass={`overflow-scroll md:!top-auto md:absolute !scrollbar-hide h-full rounded-t-2xl min-h-[20rem] h-auto max-h-[40rem]`}
        dialogOverlay={true}
        closeOnOverlayClick>
        <DialogHeader className="!p-[18px] flex justify-between !from-white !rounded-t-2xl !via-white !to-white border-b border-gray-light">
          <div className="flex flex-col items-start justify-between">
            <p className="text-base font-medium text-coal-dark">{t("select_items")}</p>
            <span className="text-sm font-normal text-coal-dark">{`${discountedProductSelectorData.totalSelectedItems}/${discountedProductSelectorData.totalItems}`}</span>
          </div>
          <div className="cursor-pointer flex text-coal-dark justify-start items-start">
            <X className="w-6 h-6" onClick={() => onSubmit(appliedPayload)} />
          </div>
        </DialogHeader>
        <DialogBody className="scrollbar-hide max-h-[36rem]">
          <div className="flex flex-col !scrollbar-hide items-start justify-between border-t border-gray-light pt-2">
            {hasMultipleGroups ? (
              discountedProductSelectorData.items.map(
                (item: DiscountedProductSelectorItemType, index: number) => (
                  <DiscountedProductGroup
                    key={`discounted-product-group-${index + 1}`}
                    productGroup={item}
                    groupIndex={index}
                    openState={openState}
                    setOpenState={setOpenState}
                  />
                ),
              )
            ) : (
              <div className="flex flex-col w-full space-y-2 py-2 px-3">
                {discountedProductSelectorData.items?.[0]?.products?.map(
                  (product: DiscountedProductSelectorProductType, pIndex: number) => (
                    <DiscountedProductCard
                      key={`discounted-product-card-${pIndex + 1}`}
                      selectedProduct={product}
                      groupIndex={0}
                      productIndex={pIndex}
                    />
                  ),
                )}
              </div>
            )}
          </div>
        </DialogBody>
        <DialogFooter>
          <PrimaryButton
            buttonText={t("confirm")}
            onClick={() => onSubmit(updatedPayload)}
            height="h-14"
            isCheckout={true}
            id="flo_address__btn"
          />
        </DialogFooter>
      </GenericDialog>
      <GenericDialog
        isOpen={discountProductSelectorModal === "FREE_GIFTS"}
        translateAxis="y"
        customClass={`overflow-scroll md:!top-auto md:absolute !scrollbar-hide h-full !rounded-t-none h-[36rem] max-h-[60vh] !bg-transparent bg-gradient-to-b from-transparent via-[#fffffff2] to-[#fffffff2]`}
        dialogOverlay={true}
        closeOnOverlayClick>
        <DialogBody className="!p-0">
          <div className="flex flex-col items-center justify-end pb-[7rem] h-full space-y-16">
            <div className="flex flex-col items-center justify-center space-y-4">
              <img src={FreeGiftsIcon} alt="free-gifts" className="w-10 h-10" />
              <div className="flex flex-col items-center justify-center space-y-1">
                <p className="text-base font-medium text-coal-dark text-center px-4">
                  {discountedProductSelectorProductDistribution.onlyFreebies && (
                    <>
                      {discountedProductSelectorProductDistribution.freebies > 1
                        ? t("get_x_freebies_on_purchase", {
                            x: discountedProductSelectorProductDistribution.freebies,
                          })
                        : t("get_a_freebie_on_purchase")}
                    </>
                  )}
                  {discountedProductSelectorProductDistribution.onlyDiscountedProducts && (
                    <>
                      {discountedProductSelectorProductDistribution.discountedProducts > 1
                        ? t("get_x_discounted_products_on_purchase", {
                            x: discountedProductSelectorProductDistribution.discountedProducts,
                          })
                        : t("get_a_discounted_product_on_purchase")}
                    </>
                  )}
                  {discountedProductSelectorProductDistribution.freebiesAndDiscountedProducts && (
                    <>
                      {discountedProductSelectorProductDistribution.freebies > 1 &&
                      discountedProductSelectorProductDistribution.discountedProducts > 1
                        ? t("get_x_freebies_and_y_discounted_products_on_purchase", {
                            x: discountedProductSelectorProductDistribution.freebies,
                            y: discountedProductSelectorProductDistribution.discountedProducts,
                          })
                        : discountedProductSelectorProductDistribution.freebies > 1 &&
                          discountedProductSelectorProductDistribution.discountedProducts === 1
                        ? t("get_x_freebies_and_a_discounted_product_on_purchase", {
                            x: discountedProductSelectorProductDistribution.freebies,
                          })
                        : discountedProductSelectorProductDistribution.freebies === 1 &&
                          discountedProductSelectorProductDistribution.discountedProducts > 1
                        ? t("get_a_freebie_and_x_discounted_products_on_purchase", {
                            x: discountedProductSelectorProductDistribution.discountedProducts,
                          })
                        : t("get_a_freebie_and_a_discounted_product_on_purchase")}
                    </>
                  )}
                </p>
                <span className="text-sm font-normal text-coal-dark">
                  {Boolean(discountedProductSelectorProductDistribution.freebies)
                    ? discountedProductSelectorProductDistribution.freebies > 1
                      ? t("free_gifts_unlocked")
                      : t("free_gift_unlocked")
                    : discountedProductSelectorProductDistribution.discountedProducts > 1
                    ? t("discounted_products_unlocked")
                    : t("discounted_product_unlocked")}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center space-y-4">
              <button
                onClick={() => setDiscountProductSelectorModal("DISCOUNTED_PRODUCT_SELECTOR")}
                className="text-sm font-medium text-btnPrimaryText bg-primary-dark px-8 py-3 rounded-lg">
                {t("select_items")}
              </button>
              <span
                onClick={() => onSubmit(appliedPayload)}
                className="text-sm font-medium cursor-pointer text-carbon-dark">
                {t("skip")}
              </span>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <div className="h-16 bg-gradient-to-b from-transparent via-[#fffffff2] to-[#fffffff2]" />
        </DialogFooter>
      </GenericDialog>
      {isLoading && <OverlaySpinner />}
    </>
  );
};

export default DiscountedProductSelectorPopup;
