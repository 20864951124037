import { ErrorType } from "lib/types/address";
import { ProgressBarType } from "@shopflo/ui";
import { CouponDisplayConfig } from "./merchant";
import { ShippingDeliveryIconType } from "components/common/ShippingHandleIcon";

export type CheckoutPayloadType = {
  merchant_id: string;
  items: CheckoutItemType[];
};

export type CheckoutItemType = {
  item_id: string;
  quantity: number;
  price: string;
};

export type ItemType = {
  item_id: string;
  item_title: string;
  quantity: number;
  price: string;
  image: string;
  is_freebie: boolean;
  variant_title: string;
  is_available: boolean;
  item_properties?: any;
  originalPrice: string;
  markupPrice: string;
  availableQuantity: number;
  isMutable: boolean;
  variant_id: string;
  product_id: string;
  item_url: string;
  is_discount_alteration: boolean;
  is_platform_fee: boolean;
  hasProductSelector: boolean;
};

export type BillingType = {
  total_payable: number;
  sub_total: number;
  total: number;
  rewards: number;
  tax: number;
  discount: number;
  shipping: number;
  cod: number;
  cod_enabled: boolean;
  taxes_included: boolean;
  serviceable: boolean;
  prepaid_discount: number;
  prepaid_total: number;
  original_sub_total: number;
};

export type CheckoutViewType = "ADDRESS_LIST" | "PAYMENTS" | "AUTH" | "NA" | "SSO" | "ORDER_SUMMARY";

export type CheckoutModalType =
  | "ADDRESS_NEW"
  | "ADDRESS_EDIT"
  | "SHIPPING_HANDLES"
  | "DISCOUNT_LIST"
  | "PAYMENT_METHOD"
  | "PAYMENT_GROUP"
  | "MAIL_COLLECT"
  | "REPLACE_COUPON"
  | "ADDRESS_NEW"
  | "ADDRESS_EDIT"
  | "ADDRESS_LIST"
  | "BILLING_ADDRESS_NEW"
  | "PROCESSING"
  | "AUTH"
  | "NONE"
  | "COMMON"
  | "EXIT_CONFIRMATION"
  | "COD_CONFIRMATION"
  | "DELETE_ADDRESS_CONFIRMATION"
  | "GIFT_CARDS"
  | "OOS"
  | "REMOVE_ITEM"
  | "OFFLINE_PAYMENT_CONFIRMATION"
  | "COD_ORDER_PLACING"
  | "COD_OTP"
  | "REMOVE_OFFERS"
  | "EXIT_SURVEY"
  | "UPSELL_VARIANT_SELECTOR"
  | "UPI_INTENT"
  | "REWARD_WALLET_CONFIRMATION"
  | "LOYALTY_CONFIRMATION"
  | "LOYALTY_INFORMATION"
  | "LOADER";

export type DiscountProductSelectorModalType = 
  | "NONE"
  | "DISCOUNTED_PRODUCT_SELECTOR"
  | "FREE_GIFTS";

export type ShippingHandleType = {
  handle_name: string;
  id: string;
  price: string;
  selected_handle: boolean;
  cod_enabled: boolean;
  cod_extra_price: string;
  etd?: string;
  etd_text?: string;
  online_enabled: boolean;
  icon?: ShippingDeliveryIconType;
  min_days_to_deliver?: number;
  max_days_to_deliver?: number;
};

export type ShippingHandlesType = ShippingHandleType[];

export type LoyaltyRedemptionHandle = {
  id: string;
  title: string;
  desc: string;
  totalPoints: number;
  totalPrice: number;
  provider: "YOTPO" | "POP_COINS" | "NECTOR" | "NONE";
};

export type NetworkLoyaltyType =
  | {
      provider: "YOTPO";
      value: YotpoLoyaltyType;
    }
  | {
      provider: "POP_COINS";
      value: StandardNetworkLoyaltyType;
    }
  | {
      provider: "NECTOR";
      value: StandardNetworkLoyaltyType;
    };

export type YotpoLoyaltyType = {
  email: string;
  totalPointsBalance: number;
  totalReducedPoints: number;
  totalReductionAmount: number;
  redemptionOptions: LoyaltyRedemptionHandle[];
  coinName: string;
  coinNamePlural: string;
};

export type StandardNetworkLoyaltyType = {
  provider: "POP_COINS" | "NECTOR" | "NONE";
  earnValue: {
    isEnabled: boolean;
    issuanceRate: number;
    normalizedValue: number;
    value: number;
  };
  redemptionId: string;
  coinName: string;
  coinNamePlural: string;
  userDetails: {
    customerId: string;
    pointBalance: number;
    maxApplicablePoints: number;
    reductionAmount: number;
  };
};

export type FloWalletData = {
  totalPointsBalance: number;
  totalAmount: number;
  maxBurnablePoints: number;
  maxBurnableAmount: number;
  maxEarnablePoints: number;
  maxEarnableAmount: number;
  earnMultiplier: number;
  burnMultiplier: number;
  coinName: string;
  coinNamePlural: string;
  earnEnabled: boolean;
  burnEnabled: boolean;
  walletDisabledMessage: string;
  insufficientBalanceMessage: string;
  coinConversionRate: number;
  personalizedWalletMessageEnabled?: boolean;
  rewardsEarnedMessage?: string;
  currencyEarnedMessage?: string;
  currencyBurnedMessage?: string;
  currencyBurnedTemplate?: string;
  earnTileCustomization?: {
    text_color: string;
    outline_color: string;
    background_color: string;
    is_credit_name_visible: string;
    outline: string;
  };
};

export type FloWalletRedemptionHandle = {
  earnMultiplier: number;
  totalReducedPoints: number;
  totalReductionAmount: number;
};

export type ThirdPartyAuthType = {
  provider: "TRUECALLER";
  redirectUrl: string;
  requestId: string;
};

export type ExitSurveyOptionType = {
  optionId: string;
  optionLabel: string;
};

export type BannerConfigType = {
  type: "AUTH" | "ORDER_SUMMARY" | "PAYMENTS" | "CART";
  bagroundColor: string;
  enabled: boolean;
  htmlString: string;
  textColor: string;
};

export type UpSellConfigType = {
  isEnabled: boolean;
  title: string;
  discountedTitle: string;
};

type CheckoutLayoutType = {
  available_elements: string[] | undefined;
  allowLogout: boolean;
  items_mutable: boolean;
  enableMarketingFlag: boolean;
};

export type PrimaryCTAConfigType = {
  ctaText: string;
  type: "CART";
};

export type wishlistVisibilityConfigType = {
  enabled: boolean;
  header: string;
  emptyHeader: string;
};

export type WishlistConfigType = {
  isEnabled: boolean;
  config: {
    checkout: wishlistVisibilityConfigType;
    cart: wishlistVisibilityConfigType;
    store: wishlistVisibilityConfigType;
  };
};

export type CheckoutUIMetadataType = {
  bannerConfig?: BannerConfigType[];
  bottomBannerConfig?: BannerConfigType[];
  upsellConfig?: UpSellConfigType;
  layout?: CheckoutLayoutType;
  progressBarConfig?: ProgressBarType;
  primaryCTAConfig?: PrimaryCTAConfigType[];
  couponDisplayConfig?: CouponDisplayConfig[];
};

export enum CheckoutActions {
  ADDRESS_SELECT = "ADDRESS_SELECT",
}

export type ActionUrlsType = {
  [key in CheckoutActions]: {
    failure_url: string;
    success_url: string;
  };
};

export type InitialCheckoutStepType = "PAYMENTS" | "ADDRESS" | "NONE";

export type OrderSummaryStyleType = "HEADER" | "TILE";

export type CheckoutComponentType = "NONE" | "SHIPPING_SECTION" | "ADDRESS_SECTION";

export type CheckoutValidationType = {
  isValid: boolean;
};

export type CheckoutAddressValidationType = CheckoutValidationType & {
  metadata?: {
    addressId: string;
    fieldData?: Record<string, ErrorType | undefined>;
  };
};

export type CartLoginValidationType = CheckoutValidationType & {
  metadata?: {
    message: string;
  };
};

export type CheckoutValidationsType = {
  address: CheckoutAddressValidationType;
  shipping: CheckoutValidationType;
  cartLoginPopup: CartLoginValidationType;
};

export type CouponReplacementInvalidityReasonType =
  | "DISCOUNTS_NOT_COMBINABLE"
  | "NO_MORE_STACK_LENGTH"
  | "NONE"
  | "REWARDS_NOT_COMBINABLE";

export type CouponReplacementType = {
  showPopup: boolean;
  appliedDiscountCode: string;
  invalidDiscountCodes: string[];
  invalidityReason: CouponReplacementInvalidityReasonType;
};

export type SavingItemsType = {
  savings: number;
  count: number;
  manualFreebieCount: number;
};

export type CheckoutExpiredType = {
  expired: boolean;
  isInvalidUser: boolean;
};

export type SelectedShippingHandleType = {
  etd: string;
  icon: ShippingDeliveryIconType;
  etd_text: string;
  min_days_to_deliver?: number;
  max_days_to_deliver?: number;
}

export type DiscountedProductSelectorDataType = {
  totalItems: number;
  totalSelectedItems: number;
  items: DiscountedProductSelectorItemType[];
};

export type DiscountedProductSelectorItemType = {
  couponCode: string;
  quantity: number;
  selectedQuantity: number;
  concessionAmount: number;
  products: DiscountedProductSelectorProductType[];
};

export type DiscountedProductSelectorProductType = {
  id: string;
  name: string;
  primaryImageUrl: string;
  variants: DiscountedProductSelectorProductVariantType[];
};

export type DiscountedProductSelectorProductVariantType = {
  id: string;
  name: string;
  selectedQuantity: number;
  isOutOfStock: boolean;
  inventoryQuantity: number;
  inventoryPolicy: "CONTINUE" | "DENY";
  originalPrice: number;
  currentPrice: number;
  discountedPrice: number;
  imageUrl: string;
};

export type DiscountedProductSelectorProductDistributionType = {
  freebies: number;
  discountedProducts: number;
  onlyFreebies: boolean;
  onlyDiscountedProducts: boolean;
  freebiesAndDiscountedProducts: boolean;
};

export type DiscountedProductSelectorPayloadType = {
  selection: {
    discount_code: string;
    items: {
      variant_id: string;
      quantity: number;
    }[];
  }[];
};

export enum BannerType {
  HEADER = "HEADER",
  FOOTER = "FOOTER",
}
