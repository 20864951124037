import { useLocale } from "lib/hooks/useLocale";
import { insertBeforeLastOccurrence, truncateString } from "lib/utils/helpers";
import React from "react";
import blockImage from "assests/images/block-image.png";
import { Minus, Plus } from "react-feather";
import Price from "components/common/Price";
import {
  DiscountedProductSelectorDataType,
  DiscountedProductSelectorProductVariantType,
} from "lib/types/checkout";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";

interface DiscountedVariantCardProps {
  productVariant: DiscountedProductSelectorProductVariantType;
  groupIndex: number;
  productIndex: number;
  variantIndex: number;
}

const DiscountedVariantCard: React.FC<DiscountedVariantCardProps> = ({
  productVariant,
  groupIndex,
  productIndex,
  variantIndex,
}) => {
  const { t } = useLocale();
  const {
    state: { discountedProductSelectorData },
    actions: { setDiscountedProductSelectorData },
  } = useCheckoutContext();
  const isAddUnavailable =
    discountedProductSelectorData?.items[groupIndex].selectedQuantity ===
      discountedProductSelectorData?.items[groupIndex].quantity ||
    (productVariant?.inventoryPolicy === "DENY" &&
      productVariant?.inventoryQuantity <= productVariant?.selectedQuantity);

  const handleEditProduct = (quantity: number) => {
    const selectedGroup = discountedProductSelectorData.items?.[groupIndex];
    const selectedProduct = selectedGroup?.products?.[productIndex];
    const selectedVariant = selectedProduct?.variants?.[variantIndex];

    const modifiedVariants = [...selectedProduct?.variants];
    modifiedVariants[variantIndex] = {
      ...selectedVariant,
      selectedQuantity: selectedVariant?.selectedQuantity + quantity,
    };

    const modifiedProducts = [...selectedGroup?.products];
    modifiedProducts[productIndex] = {
      ...selectedProduct,
      variants: modifiedVariants,
    };

    const modifiedGroups = [...discountedProductSelectorData.items];
    modifiedGroups[groupIndex] = {
      ...selectedGroup,
      products: modifiedProducts,
      selectedQuantity: selectedGroup?.selectedQuantity + quantity,
    };

    const finalData: DiscountedProductSelectorDataType = {
      ...discountedProductSelectorData,
      totalSelectedItems: discountedProductSelectorData.totalSelectedItems + quantity,
      items: modifiedGroups,
    };

    setDiscountedProductSelectorData(finalData);
  };

  return (
    <>
      <li
        key={"product-card-1"}
        className="relative flex max-h-36 min-w-[16rem] max-w-[16rem] bg-white snap-start flex-col gap-3 overflow-hidden rounded-xl border border-carbon-lighter p-2 select-none">
        <div className="flex flex-row gap-2">
          <img
            src={insertBeforeLastOccurrence(
              Boolean(productVariant?.imageUrl) ? productVariant?.imageUrl : blockImage,
              ".jpg",
              "_small",
            )}
            alt="Cart Item"
            className={`h-[4.875rem] w-[4.875rem] min-w-[4.875rem] rounded-lg border border-gray-light object-cover`}
            draggable={false}
          />
          <div className="flex flex-col w-full justify-between space-y-2">
            <p className="mt-1 text-wrap text-xs font-medium text-coal-dark">
              {truncateString(productVariant?.name, 32)}
            </p>
            <div className="flex w-full items-center justify-between">
              <div className="pr-6">
                <Price
                  total={productVariant?.discountedPrice ?? 0}
                  compareAt={productVariant?.currentPrice}
                  orientation="vertical"
                  customCSS="!text-sm"
                />
              </div>
              {Boolean(productVariant.selectedQuantity) ? (
                <div className="flex flex-row items-center justify-between space-x-1.5 rounded-md border-2 border-primary-dark px-2.5 py-1">
                  <button onClick={() => handleEditProduct(-1)}>
                    <Minus className="h-4 w-4" />
                  </button>
                  <label className="min-w-[1rem] text-center text-sm">
                    {productVariant.selectedQuantity}
                  </label>
                  <button
                    disabled={isAddUnavailable}
                    onClick={() => handleEditProduct(1)}
                    className={`${isAddUnavailable ? "text-coal-light" : "text-coal-dark"}`}>
                    <Plus className="h-4 w-4" />
                  </button>
                </div>
              ) : (
                <button
                  disabled={isAddUnavailable}
                  className="flex items-center justify-center space-x-1 rounded-lg border-2 border-primary-dark py-2 px-5 text-sm font-medium text-primary-dark disabled:text-opacity-50 disabled:cursor-not-allowed disabled:opacity-50"
                  onClick={() => handleEditProduct(1)}>
                  <Plus size={16} />
                </button>
              )}
            </div>
          </div>
        </div>
      </li>
    </>
  );
};

export default DiscountedVariantCard;
