import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { inIframe, handlePaymentSuccessRedirection, isMobile } from "lib/utils/helpers";
import { useBackButton } from "lib/hooks/useBackButton";
import { CheckoutModalType } from "lib/types/checkout";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";

interface GenericDialogProps {
  isOpen: boolean;
  setIsOpen?: (value: boolean) => void;
  translateAxis?: "x" | "y" | "z";
  dialogOverlay?: boolean;
  customClass?: string;
  redirectURL?: string;
  modalType?: CheckoutModalType;
  closeOnOverlayClick?: boolean;
}

export const GenericDialog: React.FC<GenericDialogProps> = React.memo(
  ({
    isOpen,
    setIsOpen,
    translateAxis = "z",
    dialogOverlay = false,
    customClass = "h-full md:h-[85vh]",
    children,
    modalType,
    redirectURL,
    closeOnOverlayClick = false,
  }) => {
    const {
      state: { checkoutModal },
      actions: { setCheckoutModal },
    } = useCheckoutContext();

    const browserBackBtnHandler = (e: any) => {
      if (!Boolean(isOpen)) return;
      e.preventDefault();
      if (redirectURL) {
        handlePaymentSuccessRedirection(redirectURL ?? "");
        return;
      }
      setIsOpen && setIsOpen(false);
    };
    // useBackButton(browserBackBtnHandler);

    useEffect(() => {
      if (!Boolean(isOpen)) return;

      //Nested modals will have the modalType of their parent.
      if (checkoutModal === "NONE" && modalType) {
        setCheckoutModal(modalType);

        return () => {
          setCheckoutModal("NONE");
        };
      }
    }, [isOpen]);

    return (
      <>
        <Transition appear show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className={`fixed inset-0 z-50 m-auto h-full w-full overflow-y-auto rounded-[28px] md:flex md:h-[85vh] md:w-[25rem] md:items-center`}
            onClose={() => {
              if (closeOnOverlayClick) {
                setIsOpen?.(false);
              }
            }}>
            {dialogOverlay && <Dialog.Overlay className="fixed inset-0 bg-black opacity-40" />}
            <div className="absolute h-screen text-center md:relative md:h-[85vh]">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-100"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Dialog.Overlay className="fixed inset-0" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="inline-block h-screen align-middle" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300 transform"
                enterFrom={
                  `${translateAxis}` === "z"
                    ? "opacity-0"
                    : `opacity-0 ${translateAxis === "x" ? "translate-x-full" : "translate-y-full"}`
                }
                enterTo={
                  `${translateAxis}` === "z"
                    ? "opacity-100"
                    : `opacity-100 ${translateAxis === "x" ? "translate-x-0" : "translate-y-0"}`
                }
                leave="ease-in duration-150 transform"
                leaveFrom={
                  `${translateAxis}` === "z"
                    ? "opacity-100"
                    : `opacity-100 ${translateAxis === "x" ? "translate-x-0" : "translate-y-0"} `
                }
                leaveTo={
                  `${translateAxis}` === "z"
                    ? "opacity-0"
                    : `opacity-0 ${translateAxis === "x" ? "translate-x-full" : "translate-y-full"}`
                }>
                <div
                  className={`scrollbar-hide fixed inset-x-0 bottom-0 m-auto inline-block w-full overflow-hidden rounded-3xl bg-white text-left align-middle md:top-0 md:w-[25rem] ${customClass} ${
                    isMobile() ? "rounded-b-none" : ""
                  }`}>
                  {children}
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </>
    );
  },
);

export const DialogHeader = ({ className, children }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <header
      className={`fixed z-20 flex min-h-[48px] w-full items-center overflow-hidden rounded-t-3xl border-r-inherit bg-gradient-to-b from-white via-white to-transparent px-6  md:w-[25rem] ${className} ${
        !inIframe() && "md:w-[25rem]"
      }`}>
      {children}
    </header>
  );
};

export const DialogBody = ({ className, children }: React.HTMLAttributes<HTMLDivElement>) => {
  const footerRandomId: string = Math.random().toString(36).substr(2);
  const [hasFooter, setHasFooter] = useState<boolean>(false);
  useEffect(() => {
    const footerEl = document.getElementById(footerRandomId)?.nextElementSibling;
    if (footerEl) {
      setHasFooter(true);
    }
  }, []);

  return (
    <section
      id={footerRandomId}
      className={`h-full w-full pt-16 ${
        Boolean(hasFooter) ? "pb-40" : "pb-16"
      } !overflow-y-auto scrollbar-hide ${className}`}>
      {children}
    </section>
  );
};

export const DialogFooter = ({ children, className }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <footer
      className={`fixed inset-x-0 bottom-0 z-20 m-auto max-h-36 rounded-b-[28px] bg-gradient-to-b from-transparent via-white to-white px-3 py-4 md:bottom-[30px] ${className} ${
        isMobile() ? "rounded-b-none" : ""
      } ${!inIframe() && "md:w-[25rem]"}`}>
      {children}
    </footer>
  );
};
