import BillingDetails from "components/mock-checkout/BillingDetails";
import CheckoutItems from "components/mock-checkout/CheckoutItems";
import CheckoutItemsCount from "components/checkout/CheckoutItemsCount";
import Accordion from "components/common/Accordian";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { putRequest } from "lib/core/apiClient";
import { useLocale } from "lib/hooks/useLocale";
import React, { useEffect, useRef, useState } from "react";
import { ShoppingCart } from "react-feather";
import { useSearchParams } from "react-router-dom";

interface MockCheckoutProps {}

const MockCheckout: React.FC<MockCheckoutProps> = () => {
  const { t } = useLocale();
  const {
    actions: { checkoutInit, setIsOrderSummaryOpen, setBilling },
    state: { checkoutId, checkoutItems, billing, checkoutItemsMutable, isOrderSummaryOpen },
  } = useCheckoutContext();
  const {
    actions: { setMerchantData },
  } = useMerchantContext();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [searchParams] = useSearchParams();
  const orderSummaryAccordionRef = useRef<any>(null);

  const tokenId = searchParams.get("tokenId");
  const checkoutIdParam = searchParams.get("checkoutId");

  useEffect(() => {
    if (!tokenId || !checkoutIdParam) return;
    initialize();
  }, []);

  const initialize = async () => {
    setIsLoading(true);
    try {
      const response = await putRequest(
        `/v1/cart/${checkoutId || checkoutIdParam}/checkout`,
        {},
        "CHECKOUT_PUBLIC",
      );
      checkoutInit(response);
      setBilling(response?.checkout?.pricing);
      setMerchantData(response?.merchant_attributes);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <OverlaySpinner />;

  return (
    <>
      <Accordion
        id={"mock-order-summary"}
        ref={orderSummaryAccordionRef}
        defaultOpen={true}
        titleIcon={<div className="pl-3 text-base"><ShoppingCart className="h-5 w-5 text-coal-dark" strokeWidth={2.5} /></div>}
        titleText={<div className="pl-1 text-base">{t("order_summary")}</div>}
        subTitleText={<div className="text-base"><CheckoutItemsCount showOrderSummaryTotalTxt={false} /></div>}
        callbackOnToggle={(isOpen: boolean) => {
          setIsOrderSummaryOpen(isOpen);
        }}
        content={
          <>
            {/* Line Items & Bill Summary */}
            <div className="flex w-full flex-col space-y-6">
              {Boolean(checkoutItems) && (
                <CheckoutItems
                  items={checkoutItems ?? []}
                  disableOOSItems={false}
                  checkoutItemsMutable={checkoutItemsMutable}
                />
              )}
              {Boolean(billing) && <BillingDetails {...billing} />}
            </div>
          </>
        }
      />
    </>
  );
};

export default MockCheckout;
