import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ItemType } from "lib/types/checkout";
import {
  classNames,
  inIframe,
  insertBeforeLastOccurrence,
  isEmptyObj,
  isThirdPartyCookieEnabled,
  publishPostMessage,
  sleep,
  truncateString,
} from "lib/utils/helpers";
import { ChevronDown, Edit2, ChevronRight, Gift, Heart } from "react-feather";
import defaultPackageImage from "assests/images/package.png";
import Price from "components/common/Price";
import { useLocale } from "lib/hooks/useLocale";
import {
  getItemPropertiesValues,
  getFileredItemProperties,
  getItems,
  publishItemUpdate,
  isImmutablePriceBand,
} from "lib/utils/checkout";
import { useMerchantContext } from "lib/contexts/MerchantProvider";
import { Minus, Plus, Trash2 } from "react-feather";
import OverlaySpinner from "components/common/loaders/OverlaySpinner";
import { useAuthContext } from "lib/contexts/AuthProvider";
import { fetcher, getRequest, putRequest, staticOptions } from "lib/core/apiClient";
import { errorToast, infoToast, successToast } from "lib/utils/toasters";
import useSWR, { mutate } from "swr";
import { GenericDialog } from "components/common/dialogs/GenericDialog";
import RemoveItemDialog from "components/checkout/dialogs/RemoveItemDialog";
import LinkButton from "components/common/buttons/LinkButton";
import { redirectUrl } from "lib/utils/helpers";
import { ShippingHandlesType } from "lib/types/checkout";
import { publishCartUpdate } from "lib/utils/checkout";
import { useCheckoutContext } from "lib/contexts/CheckoutProvider";
import { analyticsEvents, analyticsTypes, eventTypes } from "lib/utils/constants";
import useSendAnalyticsEvent from "lib/hooks/useAnalytics";
import { addItemToWishlist, removeItemFromWishlist } from "lib/core/apiMethods";
import { useUserContext } from "lib/contexts/UserProvider";
import { useWishlistContext } from "lib/contexts/WishlistProvider";

const MIN_ITEM_QUANTITY = 1;

interface CheckoutItemsProps {
  items: ItemType[];
  disableOOSItems?: boolean;
  checkoutItemsMutable?: boolean;
}

const CheckoutItems: React.FC<CheckoutItemsProps> = React.memo(
  ({ items, disableOOSItems = false, checkoutItemsMutable = false }) => {
    return (
      <>
        <ul className="flex w-full flex-col space-y-3">
          {items?.map((item: ItemType, index: number) => (
            <li key={`${item.item_id}_${index}`} className={`py-1.5 ${item.is_freebie ? "px-1.5" : "px-3"}`}>
              <ItemCard
                {...item}
                disableOOSItems={disableOOSItems}
                checkoutItemsMutable={checkoutItemsMutable}
              />
            </li>
          ))}
        </ul>
      </>
    );
  },
);

interface ItemCardProps extends ItemType {
  disableOOSItems: boolean;
  checkoutItemsMutable: boolean;
  isCartItem?: boolean;
  isUpdating?: boolean;
  setIsUpdating?: (value: boolean) => void;
}

export const ItemCard: React.FC<ItemCardProps> = ({
  item_title,
  quantity,
  price,
  originalPrice,
  markupPrice,
  image,
  variant_title,
  is_freebie,
  is_available,
  disableOOSItems,
  hasProductSelector,
  item_properties,
  availableQuantity,
  item_id,
  variant_id,
  isMutable,
  checkoutItemsMutable,
  isCartItem = false,
  isUpdating = false,
  setIsUpdating,
  item_url,
  is_discount_alteration,
  is_platform_fee,
}) => {
  const { t } = useLocale();
  const {
    state: { merchant },
  } = useMerchantContext();
  const {
    state: { isAuthenticated },
  } = useAuthContext();
  const {
    state: {
      checkoutId,
      checkoutView,
      billing,
      checkoutItems,
      checkoutUIMetadata,
      redirectUrl: backUrl,
      initialCheckoutStep,
      wishlistConfig,
      checkoutValidations,
    },
    actions: {
      updateCheckoutBasedOnCheckoutResponse,
      setShippingHandles,
      setCheckoutModal,
      setCheckoutItems,
      setCoupons,
      setCartDialog,
      setCheckoutValidations,
      setDiscountProductSelectorModal
    },
  } = useCheckoutContext();
  const {
    state: { wishlistedItems },
    actions: { isWishlisted, refreshWishlist },
  } = useWishlistContext();

  const { sendAnalyticsEvent } = useSendAnalyticsEvent();

  const finalItemPrice: number = parseFloat(price) * quantity;
  const finalCompareAtPrice: number =
    parseFloat(Boolean(merchant?.showOriginalPrice) ? originalPrice : markupPrice) * quantity;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [openRemoveItemDialog, setOpenRemoveItemDialog] = useState<boolean>(false);
  const [slideOutItem, setSlideOutItem] = useState<boolean>(false);

  const isWishlistEnabled = useMemo(() => {
    return !Boolean(wishlistConfig?.isEnabled)
      ? false
      : Boolean(
          Boolean(isCartItem && !Boolean(wishlistConfig?.config?.cart?.enabled)) ||
            Boolean(!isCartItem && !Boolean(wishlistConfig?.config?.checkout?.enabled)),
        )
      ? false
      : true;
  }, [isCartItem, wishlistConfig]);

  const isItemInWishlist = useMemo(() => {
    return isWishlisted(variant_id);
  }, [wishlistedItems, variant_id]);

  const getItemPrices = useCallback(() => {
    return (
      <>
        <p className="pt-1 text-sm font-medium text-coal-dark">
          <Price
            total={finalItemPrice ?? ""}
            compareAt={finalCompareAtPrice}
            orientation={Boolean(isCartItem) ? "horizontal" : "vertical"}
          />
        </p>
      </>
    );
  }, [finalCompareAtPrice, finalItemPrice, isCartItem]);

  const getItemProperties = useCallback(() => {
    if (isEmptyObj(item_properties)) return <></>;

    if (!Boolean(merchant?.showAllItemProperties) && !Boolean(merchant?.itemProperties?.length)) return <></>;

    try {
      if (Boolean(merchant?.showItemPropertyKey)) {
        const properties = getFileredItemProperties(
          item_properties,
          merchant?.itemProperties ?? [],
          merchant?.showAllItemProperties ?? false,
        );
        if (isEmptyObj(properties)) return <></>;
        const propertiesList = [];
        for (let key in properties) {
          if (properties.hasOwnProperty(key)) {
            propertiesList.push(
              <li className="w-full overflow-hidden text-ellipsis text-xs font-normal text-coal-light">
                {`${key}: ${properties[key]}`}
              </li>,
            );
          }
        }
        return propertiesList;
      }

      return (
        <li className="w-full overflow-hidden text-ellipsis text-xs font-normal text-coal-light">
          {getItemPropertiesValues(
            item_properties,
            merchant?.itemProperties ?? [],
            merchant?.showAllItemProperties ?? false,
          )}
        </li>
      );
    } catch (e) {
      console.error(e);
      return <></>;
    }
  }, [item_properties, merchant]);

  const handleItemEdit = useCallback(
    async (action: "increment" | "decrement" | "delete") => {
      let newQuantity = quantity;
      switch (action) {
        case "increment": {
          if (
            Boolean(quantity + 1 > availableQuantity) ||
            (merchant?.maxQuantityPerItem &&
              Boolean(merchant?.maxQuantityPerItem) &&
              quantity + 1 > merchant?.maxQuantityPerItem)
          )
            return;
          newQuantity++;
          break;
        }
        case "decrement": {
          if (quantity === MIN_ITEM_QUANTITY) return;
          newQuantity--;
          break;
        }
        case "delete": {
          newQuantity = 0;
          setIsDeleting(true);
          break;
        }
      }
      try {
        setIsLoading(true);
        setIsUpdating?.(true);
        const payload = {
          items: [
            {
              id: item_id,
              quantity: newQuantity,
            },
          ],
        };
        let response;
        if (isAuthenticated) {
          response = await putRequest(`/checkout/v1/checkout/${checkoutId}/items`, payload);
          const discountResponse = await getRequest(`/checkout/v1/checkout/${checkoutId}/discounts`);
          setCoupons(discountResponse);
        } else {
          response = await putRequest(`/v1/checkout/${checkoutId}/items`, payload, "CHECKOUT_PUBLIC");
          const discountResponse = await getRequest(
            `/v1/checkout/${checkoutId}/discounts`,
            "CHECKOUT_PUBLIC",
          );
          setCoupons(discountResponse);
        }

        if (Boolean(response?.items)) {
          const cartItems = getItems(response?.items);
          publishItemUpdate(item_id, action === "delete" ? checkoutItems : cartItems, action, isCartItem);
          setCheckoutItems(cartItems);
          if (!cartItems || cartItems?.length === 0) {
            publishCartUpdate([]);
            handleItemDeleteSubmit(true);
            return;
          }
        }

        if (isCartItem && action === "increment") {
          sendAnalyticsEvent({
            eventName: analyticsEvents.FLO_ADDED_TO_CART_UI,
            eventType: "click",
            eventFor: [analyticsTypes.FACEBOOK_PIXEL, analyticsTypes.SF_ANALYTICS],
            metaData: {
              checkoutItems: getItems(response?.items),
            },
          });
        }

        setIsLoading(false);
        setIsUpdating?.(false);
        setIsDeleting(false);

        const isAddressServiceable = response?.pricing?.serviceable ?? false;
        if (!Boolean(isAddressServiceable) && !isCartItem) {
          errorToast(t("serviceability_error"), 5000);
          return;
        }

        updateCheckoutBasedOnCheckoutResponse(response);

        const showShippingHandles = response?.metadata?.show_shipping_handle_selector ?? false;
        const shippingHandles = response?.metadata?.available_shipping_handles ?? [];
        setShippingHandles(shippingHandles as ShippingHandlesType);

        if (Boolean(checkoutView === "PAYMENTS")) {
          if (Boolean(checkoutUIMetadata?.upsellConfig?.isEnabled)) {
            isAuthenticated
              ? mutate(`/checkout/v1/checkout/${checkoutId}/upsell`)
              : mutate(`/v1/checkout/${checkoutId}/upsell`);
          }
          mutate(`/checkout/v2/checkout/${checkoutId}/payments`);
        }

        if (isCartItem && Boolean(checkoutUIMetadata?.upsellConfig?.isEnabled)) {
          isAuthenticated
            ? mutate(`/checkout/v1/checkout/${checkoutId}/upsell`)
            : mutate(`/v1/checkout/${checkoutId}/upsell`);
        }

        if (
          Boolean(showShippingHandles) &&
          Boolean(checkoutView === "PAYMENTS" && initialCheckoutStep !== "PAYMENTS")
        ) {
          setCheckoutModal("SHIPPING_HANDLES");
          return;
        }
        mutate(`UPI_INTENT`);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        setIsUpdating?.(false);
        setIsDeleting(false);
      }
    },
    [
      availableQuantity,
      billing.shipping,
      checkoutId,
      checkoutView,
      isAuthenticated,
      item_id,
      quantity,
      checkoutItems,
      variant_id,
    ],
  );

  const getItemPriceElement = useCallback(() => {
    return (
      <>
        {Boolean(disableOOSItems) ? (
          Boolean(is_available) ? (
            <>{getItemPrices()}</>
          ) : (
            <>
              <p className="whitespace-nowrap pt-0.5 text-sm font-medium text-ouch">{t("oos_item")}</p>
            </>
          )
        ) : (
          getItemPrices()
        )}
      </>
    );
  }, [disableOOSItems, getItemPrices, is_available, t]);

  const handleItemDeleteSubmit = async (isLastItem: boolean) => {
    //handling redirect to store on FE since BE can't do it as of now
    if (isLastItem) {
      publishCartUpdate([]);
      await sleep(50);
      // await handleItemEdit("delete");
      if (isCartItem) {
        publishPostMessage(eventTypes.CLEAR_CART_AND_CLOSE, {});
      } else {
        redirectUrl(`${backUrl}/?floRedirectStatus=oos`);
      }
      setOpenRemoveItemDialog(false);
      return;
    }
    try {
      await handleItemEdit("delete");
    } catch (e) {
      console.error(e);
    } finally {
      setOpenRemoveItemDialog(false);
    }
  };

  const handleDeleteItem = useCallback(() => {
    if (checkoutItems?.length > 1 && isWishlistEnabled) {
      handleItemEdit("delete");
    } else {
      setOpenRemoveItemDialog(true);
    }
    setSlideOutItem(false);
  }, [checkoutItems, isWishlistEnabled]);

  const handleAddToWishlist = async (deleteItem?: boolean) => {
    if (!isAuthenticated && isCartItem) {
      if (inIframe() && !isThirdPartyCookieEnabled()) {
        let redirectUrl = new URL(document.location.href);
        let checkoutUrlSearchParams = new URLSearchParams(redirectUrl.search);
        checkoutUrlSearchParams.delete("page");
        checkoutUrlSearchParams.append("checkoutId", checkoutId);
        redirectUrl.search = checkoutUrlSearchParams.toString();
        publishPostMessage(eventTypes.PARENT_REDIRECT, {
          redirectUrl: redirectUrl.href,
          clearCart: false,
        });
        return;
      }
      setCartDialog("cartAuthentication");
      setCheckoutValidations({
        ...checkoutValidations,
        cartLoginPopup: {
          isValid: false,
          metadata: {
            message: "Verify your phone number to wishlist the item",
          },
        },
      });
      return;
    }
    if (!isAuthenticated) {
      infoToast(t("login_to_add_to_wishlist"), 3000);
      return;
    }
    try {
      const response = await addItemToWishlist(merchant?.merchantId ?? "", variant_id);
      if (Boolean(response?.error)) {
        if (Boolean(deleteItem)) {
          errorToast(t("error_adding_to_wishlist"), 3000);
          handleItemEdit("delete");
        }
        throw response?.error;
      }
      if (Boolean(response)) {
        if (Boolean(deleteItem)) {
          successToast(t("added_to_wishlist"), 3000);
          handleItemEdit("delete");
        } else {
          successToast(t("added_to_wishlist"), 3000);
        }
        sendAnalyticsEvent({
          eventName: analyticsEvents.ADDED_TO_WISHLIST,
          eventType: "flo_action",
          metaData: {
            wishlistData: {
              variantId: variant_id,
              variantName: variant_title,
              productId: item_id,
              productName: item_title,
              parent: isCartItem ? "CART" : "CHECKOUT",
            },
          },
        });
      }
    } catch (e) {
      console.error(e);
      errorToast(t("error_adding_to_wishlist"), 3000);
    } finally {
      refreshWishlist();
      setSlideOutItem(false);
    }
  };

  const handleRemoveFromWishlist = async () => {
    try {
      const response = await removeItemFromWishlist(merchant?.merchantId ?? "", variant_id);
      if (Boolean(response?.error)) {
        throw response?.error;
      }
      if (Boolean(response)) {
        successToast(t("removed_from_wishlist"), 3000);
        sendAnalyticsEvent({
          eventName: analyticsEvents.REMOVED_FROM_WISHLIST,
          eventType: "flo_action",
          metaData: {
            wishlistData: {
              variantId: variant_id,
              variantName: variant_title,
              productId: item_id,
              productName: item_title,
              parent: isCartItem ? "CART" : "CHECKOUT",
            },
          },
        });
      }
    } catch (e) {
      console.error(e);
      errorToast(t("error_removing_from_wishlist"), 3000);
    } finally {
      refreshWishlist();
      setSlideOutItem(false);
    }
  };

  return (
    <div
      className={`relative flex ${isWishlistEnabled ? "overflow-x-hidden" : ""} scrollbar-hide select-none`}>
      <div
        className={classNames(
          `flex w-full min-w-full flex-row justify-between transition-all duration-300 relative`,
          is_freebie ? "rounded-2xl bg-gradient-to-r from-yay-light to-yay-lighter p-1.5 pr-2" : "",
          isCartItem ? "rounded-xl bg-[#F8F8F8] p-2" : "",
          slideOutItem ? "opacity-50 -translate-x-[14rem]" : "",
        )}>
        {isWishlistEnabled && (
          <div
            className={`absolute border-b ${
              isCartItem ? "top-[1px]" : "top-0"
            } left-[1px] border-r h-6 w-6 z-10 bg-white border-gray-light ${
              isItemInWishlist ? "text-primary-dark" : "text-gray-dark"
            } p-1 rounded-tl-md rounded-br-lg cursor-pointer`}
            onClick={() => (isItemInWishlist ? handleRemoveFromWishlist() : handleAddToWishlist())}>
            <Heart className={`h-4 w-4 ${isItemInWishlist ? "fill-primary-dark" : ""}`} strokeWidth={2.5} />
          </div>
        )}

        {slideOutItem && (
          <div
            className="absolute flex items-center justify-end top-0 left-0 w-full h-full bg-white opacity-80 pr-8 cursor-pointer"
            onClick={() => setSlideOutItem(false)}
            onTouchStart={() => setSlideOutItem(false)}>
            <ChevronRight className="h-10 w-10" />
            <ChevronRight className="-ml-8 h-10 w-10" />
          </div>
        )}
        <div className="flex w-full space-x-4">
          <div
            className={classNames(
              "relative flex items-center justify-center ",
              isCartItem ? "min-h-[75px] min-w-[75px]" : "h-16 min-h-[4rem] w-16 min-w-[4rem]",
            )}>
            {is_freebie ? (
              <div className="absolute bottom-[1px] right-[1px] flex h-6 w-6 items-center justify-center rounded-md rounded-bl-none rounded-tr-none bg-white shadow-sm">
                <Gift className="h-4 w-4 text-yay-dark" strokeWidth={2.5} />
              </div>
            ) : (
              <></>
            )}
            <LinkButton href={isCartItem ? item_url : ""}>
              <img
                src={insertBeforeLastOccurrence(image, ".jpg", "_small")}
                alt="Cart Item"
                className={classNames(
                  `rounded-lg border border-gray-light object-cover`,
                  disableOOSItems && !is_available ? "grayscale" : "",
                  isCartItem ? "h-[75px] w-[75px]" : "h-[4.188rem] w-16",
                )}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = defaultPackageImage;
                }}
              />
            </LinkButton>

            {!Boolean(checkoutItemsMutable) && quantity > 1 && (
              <div
                className={`absolute flex h-6 w-6 items-center justify-center rounded-full border border-gray-light bg-[#F8F8F8] ${
                  isWishlistEnabled ? "-right-3 -bottom-1" : "-right-3 -top-3"
                }`}>
                <span className="text-xs font-medium text-coal-dark">{quantity}</span>
              </div>
            )}
          </div>

          <ul
            className={`flex grow flex-col items-start ${
              Boolean(hasProductSelector) && isMutable ? "justify-between" : "justify-start"
            } space-y-0.5`}>
            <li className="flex w-full flex-row items-start justify-between space-x-3">
              <LinkButton href={isCartItem ? item_url : ""}>
                <p className="overflow-hidden text-ellipsis  pt-0.5 text-sm font-medium text-coal-dark">
                  {truncateString(item_title, 44)}
                </p>
              </LinkButton>
              {!Boolean(hasProductSelector) &&
                ((Boolean(checkoutItemsMutable) && Boolean(isMutable)) ||
                  (isCartItem && !is_freebie && !is_discount_alteration && !is_platform_fee)) && (
                  <button
                    className={classNames(
                      "cursor-pointer rounded-lg border border-gray-light p-1",
                      isCartItem ? "bg-white" : "",
                    )}
                    disabled={isUpdating || isLoading}
                    onClick={() =>
                      !isWishlistEnabled ? setOpenRemoveItemDialog(true) : setSlideOutItem(true)
                    }>
                    <Trash2 className="h-3.5 w-3.5 text-coal-light outline-none" strokeWidth={2.5} />
                  </button>
                )}
              {Boolean(hasProductSelector) && isMutable && (
                <button
                  className={classNames("cursor-pointer rounded-lg border border-gray-light p-1 bg-white")}
                  onClick={() => setDiscountProductSelectorModal("DISCOUNTED_PRODUCT_SELECTOR")}>
                  <Edit2 className="h-3.5 w-3.5 text-coal-light outline-none" strokeWidth={2.5} />
                </button>
              )}
            </li>
            {variant_title !== "Default Title" && (
              <li className="w-full overflow-hidden text-ellipsis text-xs font-normal text-coal-light">
                {variant_title}
              </li>
            )}

            {getItemProperties()}
            {Boolean(hasProductSelector) && isMutable && (
              <li className="flex w-full flex-row items-end justify-between">
                <div
                  className="flex px-2 py-1 border space-x-1 border-gray-light rounded-md bg-white cursor-pointer"
                  onClick={() => setDiscountProductSelectorModal("DISCOUNTED_PRODUCT_SELECTOR")}>
                  <p className="text-xs font-medium text-coal-dark">{quantity}</p>
                  {/* <ChevronDown className="h-3.5 w-3.5 text-coal-light" strokeWidth={2.5} /> */}
                </div>
                {getItemPriceElement()}
              </li>
            )}

            {Boolean(checkoutItemsMutable) &&
              !Boolean(disableOOSItems) &&
              !Boolean(hasProductSelector) && (
                <li className="flex w-full flex-row items-end justify-between pt-1">
                  {Boolean(finalItemPrice) &&
                  !isImmutablePriceBand(markupPrice, merchant) &&
                  (Boolean(isMutable) ||
                    (isCartItem && !is_freebie && !is_discount_alteration && !is_platform_fee)) ? (
                    <>
                      <div
                        className={classNames(
                          "flex h-6 w-[4.25rem] flex-row items-center rounded border",
                          isCartItem ? "border-carbon-lighter bg-white" : "border-gray-light",
                        )}>
                        <button
                          className={classNames(
                            `flex h-full w-full grow basis-1/3 items-center justify-center`,
                            Boolean(quantity === MIN_ITEM_QUANTITY) || isUpdating || isLoading
                              ? "cursor-not-allowed text-carbon-lighter"
                              : "cursor-pointer bg-gray-light text-coal-light",
                            "rounded-l",
                            isCartItem ? "bg-[#F8F8F8]" : "",
                          )}
                          disabled={isUpdating || isLoading}
                          onClick={() => handleItemEdit("decrement")}>
                          <Minus className="h-3.5 w-3.5 outline-none" strokeWidth={2.5} />
                        </button>

                        <p className="grow basis-1/3 text-center text-xs font-medium text-coal-dark">
                          {quantity}
                        </p>
                        <button
                          className={classNames(
                            `flex h-full w-full grow basis-1/3 items-center justify-center rounded-r`,
                            Boolean(quantity + 1 > availableQuantity) ||
                              (merchant?.maxQuantityPerItem &&
                                Boolean(merchant?.maxQuantityPerItem) &&
                                quantity + 1 > merchant?.maxQuantityPerItem) ||
                              isUpdating ||
                              isLoading
                              ? "cursor-not-allowed text-carbon-lighter"
                              : "cursor-pointer bg-gray-light text-coal-light",
                            isCartItem ? "bg-[#F8F8F8]" : "",
                          )}
                          disabled={isUpdating || isLoading}
                          onClick={() => handleItemEdit("increment")}>
                          <Plus className="h-3.5 w-3.5 outline-none" strokeWidth={2.5} />
                        </button>
                      </div>
                    </>
                  ) : (
                    <div className="flex h-6 w-6 items-center justify-center rounded border border-gray-light bg-white">
                      <p className="text-xs font-medium text-coal-dark">{quantity}</p>
                    </div>
                  )}

                  {getItemPriceElement()}
                </li>
              )}
          </ul>
        </div>
        {!Boolean(checkoutItemsMutable) && !Boolean(hasProductSelector) && getItemPriceElement()}
      </div>
      {isWishlistEnabled && (
        <div
          className={`flex min-w-[14rem] transition-all duration-300 rounded-r-xl overflow-hidden ${
            slideOutItem ? "-translate-x-[14rem]" : ""
          }`}>
          {isWishlistEnabled && !isItemInWishlist && (
            <button
              className="flex flex-col items-center space-y-2 justify-center w-1/2 py-1 text-primary-dark cursor-pointer bg-primary-lighter"
              onClick={() => handleAddToWishlist(true)}>
              <Heart className="h-4 w-4" />
              <p className="text-xs font-medium">{t("save_for_later")}</p>
            </button>
          )}
          <button
            className={`flex flex-col items-center justify-center ${
              isWishlistEnabled && !isItemInWishlist ? "w-1/2" : "w-full"
            } space-y-2 py-1 text-ouch cursor-pointer bg-[#cb271140]`}
            onClick={handleDeleteItem}>
            <Trash2 className="h-4 w-4" />
            <p className="text-xs font-medium">{t("remove")}</p>
          </button>
        </div>
      )}

      <GenericDialog
        isOpen={openRemoveItemDialog}
        setIsOpen={() => setOpenRemoveItemDialog(false)}
        translateAxis="y"
        customClass="overflow-scroll md:!top-auto md:absolute"
        dialogOverlay={true}
        modalType="REMOVE_ITEM">
        <RemoveItemDialog
          setIsOpen={() => setOpenRemoveItemDialog(false)}
          itemName={item_title}
          onConfirm={handleItemDeleteSubmit}
          isCartItem={isCartItem}
        />
      </GenericDialog>

      {((!isCartItem && isLoading) || isDeleting) && <OverlaySpinner />}
    </div>
  );
};

export default CheckoutItems;
